import React, { useState } from 'react'
import './head-bar.styles.scss'

import { useSelector } from 'react-redux';
import UserIcon from 'components/user-icon/user-icon.component';
import UserDropdown from './user-dropdown.component';
import SearchInput from '../SearchBar/search-input.component';
import SearchModal from 'components/SearchBar/search-results-modal.component';
import SearchContainer from 'components/SearchBar/search-container.component';
import { useNavigate } from 'react-router-dom';

const HeadBar = ({ setShowSearchModal }) => {

    const user = useSelector(state => state.userData.currentUser);
    const profile = useSelector(state => state.userData.userProfile);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="head-bar-container">
            <div className="logo-container" onClick={() => navigate('/home')}>
                    {/* <div className="g-card"> */}
                    
                    <img src={require("assets/logo_long.png")} alt="Logo" className="logo" />
                {/* </div> */}
                
            </div>
            <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' />
            <div className='user-box'>
                <div className='user-info'>
                    {
                        (user && profile) &&
                        <>
                            <div className='token-display' title='Get more tokens'>
                                <img src={require('assets/icons/chip.png')} />
                                <span>{profile.tokenCount ?? '0'}</span>
                            </div>
                            <div className='user-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                <UserIcon profile={profile} reverse={true} />
                            </div>

                        </>
                        
                    }
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>
    )
}

export default HeadBar