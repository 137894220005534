import React, { useEffect, useState } from 'react';
import './header-mobile.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import SidebarLeft from 'components/sidebar-left/sidebar-left.component';

const HeaderMobile = ({ isMobileDevice }) => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const profile = useSelector(state => state.userData.userProfile)
    const user = useSelector(state => state.userData.currentUser)
    
    useEffect(() => {
        // document.getElementById('slide-menu').classList.toggle('show');
    }, [menuOpen])

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const menuItems = [
        {
            'path': '',
            'label': 'My List'
        }
    ]

    return (
        <div className="mobile-header">
            <div className="mobile-header__logo">
                <img src={require('assets/logo_long.png')} alt="Logo" />
            </div>
            <div className="mobile-header__menu">
                
                <button className="mobile-header__menu-button" id="slide-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className={`mobile-header__menu-items ${menuOpen ? "show": ""}`}>
                    <SidebarLeft isMobileDevice={isMobileDevice} toggleMenu={toggleMenu} />
                </div>
                
            </div>
            
        </div>
    );
};

export default HeaderMobile;