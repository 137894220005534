import React from 'react'
import './landing-page.styles.scss'

import Layout from 'components/layout/layout.comp';
import { SignInWithGoogle, UseCloudFunction } from 'firebase-utils/firebase.utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {

    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const navigate = useNavigate();

    async function getTestAPIData() {
        const res = await UseCloudFunction('getTestAPIResults', {'data': 'data'})
        console.log(res)
    }

    return (
        <div className='landing-page'>
            <img src={require('assets/logo_tall.png')} className='landing-icon' />
            <div className="g-space-20"></div>
            {
                (currentUser && userProfile) ?
                <button className='g-button big' onClick={() => navigate('/home')}>Home</button>
                :
                <button className='g-button big' onClick={() => SignInWithGoogle()}>Sign In</button>
            }
            <br />
            <button className='g-button big' onClick={() => getTestAPIData()}>Test API</button>
        </div>
    )
}

export default LandingPage;