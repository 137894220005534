import React from 'react';
import './sidebar-container.styles.scss';
import SidebarRight from './sidebar-right.component';

const SidebarRightContainer = () => {
    
    return (
        <div className='sidebar-right-container'>
            <SidebarRight />
        </div>
    )
}

export default SidebarRightContainer