import React, { useState, useEffect } from 'react';
import './modal.styles.scss';

import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ isOpen, onClose, children, closeButton, width, headText }) => {
    
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const handleTransitionEnd = () => {
        if (!isOpen) {
            // setIsMounted(false);
        } 
    };

    useEffect(() => {
        if (isOpen) {
		    document.body.style.overflow = 'hidden'; 
            
		} else {
			document.body.style.overflow = 'unset';
		}
    }, [isOpen])

    if (!isMounted) {
        return null;
    }

    return ReactDOM.createPortal(
        <div
          className={`modal ${isOpen ? 'open' : ''}`}
          onTransitionEnd={handleTransitionEnd}
        >
            <div className="modal__overlay" />
            <div className={`modal__content ${width ? width: ''}`}>
                <div className='modal-head'>
                    <div className='head-text'>
                        {headText ? headText : ' '}
                    </div>
                    {   
                        (closeButton) &&
                        <div className='close-button-div'>
                            <FontAwesomeIcon className='close-button' icon={faXmark} size='lg' onClick={onClose} />
                        </div>
                    }
                </div>
                {/* <div className="g-space-20"></div> */}
                <div className='children-div'>
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;