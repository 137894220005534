import React, { useEffect } from 'react'
import './logout-page.styles.scss'

import Loader from 'components/loader/loader.comp'
import { signOut } from 'firebase-utils/firebase.utils';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        signUserOut();
    }, []);

    async function signUserOut() {
        const res = await signOut();
        if (res.message) {
            navigate('/')
        }
    }

    return (
        <div className='loading-page'>
            <Loader />  
        </div>
    )
}

export default LogoutPage