import React from 'react'
import { Route, Routes } from 'react-router-dom'
import StartWatching from './start-watching/start-watching.component'
import WatchList from './watchlist/watchlist.component'

const HomeContent = () => {
    return (
        <div className='home-content'>
            
            <Routes>
                <Route path='' element={'HomeContent'}></Route>
                <Route path='watchlist/*' element={<WatchList />}></Route> 
            </Routes>
        </div>
    )
}

export default HomeContent