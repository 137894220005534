import React, { useState } from 'react'
import './search-container.styles.scss'

import SearchInput from './search-input.component'
import SearchResultsModal from './search-results-modal.component'

const SearchContainer = ({ location }) => {

    const [ showSearchModal, setShowSearchModal ] = useState(false);

    return (
        <div className='search-container'>
            <SearchInput setShowSearchModal={setShowSearchModal} location={location} />
            <SearchResultsModal isOpen={showSearchModal} onClose={() => setShowSearchModal(false)} />
        </div>
    )
}

export default SearchContainer