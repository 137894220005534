import Modal from 'components/modal/modal.component'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SearchInput from './search-input.component'
import SearchResults from './search-results.component'

const SearchModal = ({ isOpen, onClose }) => {

    const searchText = useSelector(state => state.contentData.searchText)
    const searchResults = useSelector(state => state.contentData.searchResults)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    return (
        <div>
            <Modal isOpen={isOpen} onClose={onClose} closeButton={true} cls='narrow'>
                <div className="g-space-10"></div>
                <SearchInput location='search-modal' />
                <SearchResults searchResults={searchResults}/>
            </Modal> 
        </div>
    )
}
    
export default SearchModal;