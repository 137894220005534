import React, { useEffect } from 'react';
import './watchlist-item.styles.scss';
import { useParams } from 'react-router-dom';

const WatchListItem = () => {

    const { itemId } = useParams();

    useEffect(() => {
        if (itemId) {
            console.log(itemId);
        }
    }, [itemId])

    return (
        <div className='watchlist-item-container'>
            <div className='watchlist-item'>
                
            </div>
        </div>
    )
}

export default WatchListItem