import React, { useEffect, useRef } from 'react'
import './home.styles.scss'

import SearchInput from 'components/SearchBar/search-input.component';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import SearchContainer from 'components/SearchBar/search-container.component';
import { Route, Routes } from 'react-router-dom';
import HomeContent from 'components/HOME/home-content.component';
import StartWatching from 'components/HOME/start-watching/start-watching.component';
import { rtDb } from 'firebase-utils/firebase.utils';
import { equalTo, get, limitToLast, off, onChildAdded, onValue, orderByChild, query, ref } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { setMyWatchList } from 'state/slices/contentSlice';
import { orderBy, startAfter, startAt } from 'firebase/firestore';

const HomePage = () => {

    const isMobileDevice = useMediaQuery({query: "(max-device-width: 767px)"});
    const profile = useSelector(state => state.userData.userProfile);
    const watchlist = useSelector(state => state.contentData.myWatchList);
    const dispatch = useDispatch();
    const listRef = useRef();

    useEffect(() => {
        getInitialWatchList();
        const newWatchlistRef = query(ref(rtDb, `watchlist/${profile.appId}`), orderByChild('ts'), limitToLast(1));
        onChildAdded(newWatchlistRef, (data) => {
            console.log(data.val())
            const item = data.val();
            dispatch(setMyWatchList({...watchlist, ...{[`${item.ts}-${item.id}`]: item}}));
        });

        return () => {
            console.log('closed')
            off(newWatchlistRef);
        }
    }, [])

    async function getInitialWatchList() {
        const watchlistRef = query(ref(rtDb, `watchlist/${profile.appId}`), orderByChild('status'), equalTo('active'));
        onValue(watchlistRef, (data) => {
            console.log(data.val())
            dispatch(setMyWatchList(data.val()))
        }, {onlyOnce: true});
    }

    return (
        <div className='home-page'>
            {isMobileDevice 
                ?
                <SearchContainer />
                :
                <HomeContent />
            }
            
        </div>
    )
}

export default HomePage;