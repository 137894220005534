import React, { useEffect } from 'react';
import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LandingPage from 'pages/landing-page/landing-page.comp';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from 'state/slices/userSlice';
import { onAuthStateChanged } from 'firebase/auth';
import { UseCloudFunction, auth } from 'firebase-utils/firebase.utils';
import LoadingPage from 'pages/loading/loading-page.page';
import LogoutPage from 'pages/logout/logout-page.page';
import HomePage from 'pages/home/home.page';
import Layout from 'components/layout/layout.comp';
import ProfilePage from 'pages/profile/profile.page';
import { useMediaQuery } from 'react-responsive'

function App() {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.userData.currentUser)
    const userProfile = useSelector(state => state.userData.userProfile)

    useEffect(() => {
        signInUser();

    }, [currentUser])
    
    function signInUser() {
        const href = window.location.href
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // console.log(user)
                if (!currentUser) {
                    const newUser = {
                        displayName: user.displayName,
                        email: user.email,
                        metadata: {
                        createdAt: Number(user.metadata.createdAt),
                        lastLogInAt: user.metadata.lastLogInAt
                        },
                        uid: user.uid,
                        photoURL: user.photoURL,
                        provider: user.providerData[0].providerId
                    }
                    dispatch(setCurrentUser({...newUser}));
                }
                navigate('/loading')  
            } else {
                if (currentUser) {
                    dispatch(setCurrentUser(null))
                }
                if (!href.includes('/signin') && !href.includes('/register') && !href.includes('/info')) {
                    navigate('/')
                }
            }
        });
    }

    return (
        <div className="App">
            <Routes>
                <Route path='' element={<LandingPage />}></Route>
                <Route path='loading' element={<LoadingPage />}></Route>
                <Route path='logout' element={<LogoutPage />}></Route>
                <Route path='home/*' element={<Layout><HomePage /></Layout>}></Route>
                <Route path='profile' element={<ProfilePage />}></Route>
            </Routes>
        </div>
    );
}

export default App;
