import Modal from 'components/modal/modal.component'
import './services-modal.styles.scss'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { servicesList } from './services-list.js'
import { setMyServices } from 'state/slices/userSlice'


const SerivcesModal = ({ isOpen, onClose }) => {

    const myServices = useSelector(state => state.userData.myServices);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(myServices);
    }, [myServices])

    useEffect(() => {
        console.log(servicesList);
    }, [servicesList])

    function toggleService(id) {
        const newServicesList = [...myServices];
        if (newServicesList.includes(id)) {
            newServicesList.splice(newServicesList.indexOf(id), 1);
        } else {
            newServicesList.push(id)
        }
        dispatch(setMyServices(newServicesList));
    }

    return (
        
        <Modal isOpen={isOpen} onClose={onClose} headText='My Services' closeButton={true} width='narrow'>
            <div className='services-modal'>
                <p>Choose the services that you use so you can easily use them in your posts.</p>
                <div className='services-container'>
                    {
                        (servicesList) &&
                        Object.values(servicesList).sort((a,b) => a.name > b.name ? 1 : -1)
                        .map(service => (
                            <div key={service.id} className={`service-option ${myServices && myServices.includes(service.id) ? 'active': ''}`} onClick={() => toggleService(service.id)}>
                                <img src={service.icon} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}

export default SerivcesModal