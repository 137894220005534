import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  userProfile: null,
  myServices: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
        state.currentUser = action.payload
    },
    setUserProfile: (state, action) => {
        state.userProfile = action.payload
    },
    setMyServices: (state, action) => {
        state.myServices = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setCurrentUser, 
  setUserProfile,
  setMyServices
} = userSlice.actions

export default userSlice.reducer