import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './profile-icon.styles.scss'

import "croppie/croppie.css"
import Croppie from "croppie"

import UIkit from 'uikit'

const ProfileIcon = ({ formData, saveData }) => {
	
	const provider = useSelector(state => state.userData.currentUser.provider)
	const photoURL = useSelector(state => state.userData.currentUser.photoURL)
	
	const [ showIconModal, setShowIconModal ] = useState(false) 
	const [ croppedImage, setCroppedImage ] = useState('')
	const [ croppie, setCroppie ] = useState(null)
    let croppieInstance

	function hideModal() {
		setShowIconModal(false)
	}
	
	const chooseGoogleIcon = async () => {
		setCroppedImage('')
		document.getElementById('selected-image').classList.remove('hide')
		destroyCroppie()
		saveData('icon', {upload: false, url: photoURL})
		console.log('choose Google Icon')
	}

	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

	function handleUpload(file) {
		document.getElementById('selected-image').classList.add('hide')
		destroyCroppie()
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				// setCroppedImage(newFile)
				const el = document.getElementById("uploaded-image")
				if (el) {
					croppieInstance = new Croppie(el, {
						viewport: { width: 150, height: 150 },
						boundary: { width: 150, height: 150 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
					setCroppie(croppieInstance)
				}
				setCroppedImage(newFile)
				saveData('icon', {upload: true, croppie: croppieInstance, type: fileType, name: fileName})
				return;
			}
			document.getElementById('selected-image').classList.remove('hide')
			const newFile = window.URL.createObjectURL(file)
			saveData('icon', {upload: true, url: newFile, type: fileType, name: fileName, file: file})
			return;
		}
		UIkit.modal.alert('Please upload an image file.')
		return;
	}

	return (
		<div>
            <label>You need an icon...</label>
			<div className="g-grid">
                <div className="g-half-col">
					<div className="image-buttons">
						<div className="uk-margin">
							{
								(provider == 'google.com') 
								? <button className="g-button" type="button" onClick={() => chooseGoogleIcon()}>Use Your Google Icon</button>
								: ''
							}
						</div>
						<div className="uk-margin">
							<div style={{cursor: 'pointer'}}>
								<input type="file" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
								<button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload an Icon</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="g-half-col">
					<div className="image-div">
						<div id="uploadDiv">
							<div id="selected-image">
								{ 
									(formData.icon) 
										? <img className="profile-image" referrerPolicy="no-referrer" src={formData.icon.url || formData.icon} />
										: <img className="profile-image" referrerPolicy="no-referrer" src={require("assets/placeholder-icon.jpg")} />
								}
							</div>
							<div id="uploaded-image">
							
							</div>
							<a className="rotateButton" id="rotateButton"><i className="large redo icon"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileIcon


//	// profileIcons.map(img => (
									// 	<img key={img.id} className='profile-icons' value={img.url} onClick={(e) => chooseIcon(e.target.value)} src={img.url} />
									// ))
								