import React from 'react'
import './watchlist.styles.scss'
import { useSelector } from 'react-redux'
import { servicesList } from 'components/services-modal/services-list.js'
import { Route, Routes, useNavigate } from 'react-router-dom'
import WatchListItem from './watchlist-item.component'

const WatchList = ({ sidebar }) => {
    
    const watchlist = useSelector(state => state.contentData.myWatchList);
    const navigate = useNavigate();

    return (
        <div className='watchlist-container'>
            <Routes>
                <Route path='' element={
                    sidebar 
                    ?
                    <div className='watchlist-sidebar'>
                    {
                        watchlist &&
                        Object.values(watchlist).sort((a,b) => a.ts < b.ts ? 1 : -1)
                        .map(item => (
                            <div key={item.id} className='watchlist-sidebar-item' onClick={() => navigate(`/home/watchlist/${item.id}`)}>
                                <img src={item.image} className='watchlist-sidebar-image' />
                                <div className='watchlist-sidebar-text'>
                                    {item.title}<br />
                                    <img src={servicesList[item.service].icon} className='watchlist-sidebar-service' />
                                </div>
                                
                            </div>
                        ))
                    }
                    </div>
                    :
                    <div className='watchlist-page'>
                        
                    </div>
                }></Route>
                <Route path=':itemId' element={<WatchListItem />}></Route>
            </Routes>
            {
                
            }
        </div>
    )
}

export default WatchList