import React, { useState, useEffect } from 'react'
import './sidebar-left.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive, faCode, faFileText, faFolder, faMailForward, faPenFancy, faStar, faUpload, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { mediaQueries } from 'utils/responsiveQueries';
import { useMediaQuery } from 'react-responsive';
import SerivcesModal from 'components/services-modal/services-modal.component';
import SearchModal from 'components/SearchBar/search-modal.component';

const SidebarLeft = ({ toggleMenu }) => {

    const [ viewDrop, setViewDrop ] = useState(false);
    const [ showServices, setShowServices ] = useState(false);
    const [ showSearchModal, setShowSearchModal ] = useState(false);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    const navigate = useNavigate();

    function chooseMenuItem(e) {
        navigate(e);
        if (toggleMenu) {
            setTimeout(() => {        
                toggleMenu();
            }, 200);
        }
    }

    return (
        <div>
            <div className={`sidebar-container ${isMobileDevice ? 'mobile' : ''}`}>

                
                <div className="g-height-20"></div>
                <div className="button-container">
                    
                    <button className={`start-button ${showSearchModal ? 'clicked' : ''}`} onClick={() => setShowSearchModal(true)}>
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="button-text">Get Started</span>
                    </button>
                </div>
                <div className={`drop-container ${viewDrop ? 'active' : ''}`}>
                    <div className="drop-menu">
                        
                        <div className="drop-menu-item" id="newFolder" onClick={() => setShowSearchModal(true)}> 
                            <img src={require('assets/icons/play.png')} className="drop-menu-item-icon" />
                            Add To WatchList
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newsuggestion')}> 
                            <img src={require('assets/icons/suggestion.png')} className="drop-menu-item-icon" />
                            Recommend
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newchat')}> 
                            <img src={require('assets/icons/chat.png')} className="drop-menu-item-icon" />
                            New Chat
                        </div>
                        
                        
                    </div>
                </div>
                <div className="g-height-20"></div>
                <div className="menu-container">
                    <div className="menu-item" onClick={() => chooseMenuItem('/home')}>
                        <img src={require('assets/icons/home.png')} className='menu-item-icon' />
                        Home
                    </div>
                    <div className="menu-item" onClick={() => chooseMenuItem('watchlist')}>
                        <img src={require('assets/icons/play.png')} className='menu-item-icon' />
                        My WatchList
                    </div>
                    <div className="menu-item" onClick={() => chooseMenuItem('clans')}>
                        <img src={require('assets/icons/clans.png')} className='menu-item-icon' />
                        My Clans
                    </div>
                    <div className="menu-item" onClick={() => chooseMenuItem('chats')}>
                        <img src={require('assets/icons/chat.png')} className='menu-item-icon' />
                        My Chats
                    </div>
                    <div className="menu-item" onClick={() => setShowServices(true)}>
                        <img src={require('assets/icons/puzzle.png')} className='menu-item-icon' />
                        My Services
                    </div>
                    
                </div>
            </div>
            <SerivcesModal isOpen={showServices} onClose={() => setShowServices(false)} />
            <SearchModal isOpen={showSearchModal} onClose={() => setShowSearchModal(false)} />
        </div>
    )
}

export default SidebarLeft;