import React, { useState } from 'react';
import './search-results.styles.scss';
import Loader from 'components/loader/loader.comp';
import { AddToWatchList } from 'utils/watchList';
import { useSelector } from 'react-redux';
import { servicesList } from 'components/services-modal/services-list';
import { useMediaQuery } from 'react-responsive';

const SearchResults = ({ searchResults }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ service, setService ] = useState(null)
    const isMobileDevice = useMediaQuery({query: "(max-device-width: 767px)"});

    return (
        <div className='search-results'>
        {
            (searchResults && profile) 
            ?
            searchResults.map(res => (
                isMobileDevice 
                ?
                <div key={res.id} className='mobile-result-card'>
                    <img src={res.image} className='result-image' />
                    <div className='result-text'>
                        <h4 className="card-title">{res.title}</h4>
                        <p className="card-description">{res.description}</p>
                        <div className='field'>
                            <select className='small' onChange={(e) => setService(e.target.value)}>
                                <option value=''>Choose a streaming service</option>
                                {
                                    servicesList &&
                                    Object.values(servicesList).sort((a,b) => a.name > b.name ? 1 : -1)
                                    .map(service => (
                                        <option key={service.id} value={service.id}>{service.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="card-buttons">
                            <button className="card-button" onClick={() => AddToWatchList(res, profile, service)}>Add To WatchList</button>
                            <button className="card-button">Button 2</button>
                        </div>
                    </div>
                </div>
                :
                <div key={res.id} className='result-card'>
                    <img src={res.image} className='result-image' />
                    <div className='result-text'>
                        <h5 className="card-title">{res.title}</h5>
                        <p className="card-description">{res.description}</p>
                        <div className='field'>
                            <select className='small' onChange={(e) => setService(e.target.value)}>
                                <option value=''>Choose a streaming service</option>
                                {
                                    servicesList &&
                                    Object.values(servicesList).sort((a,b) => a.name > b.name ? 1 : -1)
                                    .map(service => (
                                        <option key={service.id} value={service.id}>{service.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="card-buttons">
                            <button className="card-button" onClick={() => AddToWatchList(res, profile, service)}>Add To WatchList</button>
                            <button className="card-button">Button 2</button>
                        </div>

                    </div>
                </div>
            ))
            :
            <div className='modal-loader'>
                <Loader />
            </div>

        }
        </div>
    )
}

export default SearchResults