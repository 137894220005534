import React, { useEffect } from 'react'
import './loading-page.styles.scss'

import Loader from 'components/loader/loader.comp'
import { useDispatch, useSelector } from 'react-redux'
import { UseCloudFunction } from 'firebase-utils/firebase.utils'
import { useNavigate } from 'react-router-dom'
import { setUserProfile } from 'state/slices/userSlice'

const LoadingPage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const currentUser = useSelector(state => state.userData.currentUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userProfile) {
            getUserProfile();
        } else {
            setTimeout(() => {
                navigate('/home')
            }, 1000);
        }
    }, [])

    async function getUserProfile() {
        const profile = await UseCloudFunction('signIn', {'email': currentUser.email});
        if (profile.profile) {
            dispatch(setUserProfile(profile.profile))
            navigate('/home');
        } else {
            navigate('/profile');
        }
    }

    return (
        <div className='loading-page'>
            <Loader />  
        </div>
    )
}

export default LoadingPage