import React from 'react'
import './search-results-modal.styles.scss'

import Modal from 'components/modal/modal.component'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentChoice } from 'state/slices/contentSlice'
import { useNavigate } from 'react-router-dom'
import SearchResults from './search-results.component'

const SearchResultsModal = ({ isOpen, onClose }) => {
    
    const searchText = useSelector(state => state.contentData.searchText)
    const searchResults = useSelector(state => state.contentData.searchResults)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function startWatching(id) {
        dispatch(setCurrentChoice(id));
        navigate('startwatching');
    }
    
    return (
        <div>
            <Modal isOpen={isOpen} onClose={onClose} closeButton={true} cls='narrow'>
                <div className='modal-content'>
                    <h4>Results for "{searchText ?? ''}"</h4>
                    <SearchResults searchResults={searchResults} startWatching={startWatching} />
                </div>
            </Modal>
        </div>
    )
}

export default SearchResultsModal;