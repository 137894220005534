export const servicesList = {
    "001": {
        id: "001",
        name: "Apple TV+",
        icon: require("assets/service-icons/appletv.jpg"),
    },
    "002": {
        id: "002",
        name: "Criterion",
        icon: require("assets/service-icons/criterion.jpg"),
    },
    "003": {
        id: "003",
        name: "Disney+",
        icon: require("assets/service-icons/disney.jpg"),
    },
    "004": {
        id: "004",
        name: "Hulu",
        icon: require("assets/service-icons/hulu.jpg"),
    },
    "005": {
        id: "005",
        name: "Max",
        icon: require("assets/service-icons/max.jpg"),
    },
    "006": {
        id: "006",
        name: "Netflix",
        icon: require("assets/service-icons/netflix.jpg"),
    },
    "007": {
        id: "007",
        name: "Paramount+",
        icon: require("assets/service-icons/paramount.jpg"),
    },
    "008": {
        id: "008",
        name: "Amazon Prime",
        icon: require("assets/service-icons/prime.jpg"),
    },
    "009": {
        id: "009",
        name: "Vudu",
        icon: require("assets/service-icons/vudu.jpg"),
    },
    "010": {
        id: "010",
        name: "YouTube",
        icon: require("assets/service-icons/youtube.jpg"),
    },
    "011": {
        id: "011",
        name: "YouTube TV",
        icon: require("assets/service-icons/youtubetv.jpg"),
    },
}