import React, { useState } from 'react';
import './layout.styles.scss';

import HeadBar from 'components/head-bar/head-bar.component';
import HeaderMobile from 'components/head-mobile/header-mobile.component';
import { Desktop, Mobile } from 'utils/responsiveQueries';
import SidebarLeftContainer from 'components/sidebar-left/sidebar-container.component';
import SidebarRightContainer from 'components/sidebar-right/sidebar-container.component copy';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {

    const location = useLocation();

    return (
        <div>
            <Desktop>
                <HeadBar />
                <div className='desktop-container'>
                    <div className='sidebar'>
                        <SidebarLeftContainer />
                    </div>
                    
                    <div className='content-container'>
                        {children}
                    </div>
                    {
                        (location.pathname === '/home') &&
                        <div className='sidebar'>
                            <SidebarRightContainer />
                        </div>
                    }
                </div>
            </Desktop>

            <Mobile>
                <HeaderMobile />
                <div className='mobile-content-container'>
                    {children}
                </div>
            </Mobile>
            
        </div>
    )
}

export default Layout