import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchResults: null,
  searchText: null,
  currentChoice: null,
  myWatchList: null,
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = action.payload
    },
    setSearchText: (state, action) => {
        state.searchText = action.payload
    },
    setCurrentChoice: (state, action) => {
        state.currentChoice = action.payload
    },
    setMyWatchList: (state, action) => {
        state.myWatchList = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { 
  setSearchResults,
  setSearchText,
  setCurrentChoice,
  setMyWatchList,
  
} = contentSlice.actions

export default contentSlice.reducer