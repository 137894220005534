import React, { useState, useEffect } from 'react'
import './search-input.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { setSearchText, setSearchResults } from 'state/slices/contentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { mediaQueries } from 'utils/responsiveQueries';
import { UseCloudFunction } from 'firebase-utils/firebase.utils';

const SearchInput = ({ setShowSearchModal, location }) => {
    
    const dispatch = useDispatch();
    const searchText = useSelector(state => state.contentData.searchText);
    const searchResults = useSelector(state => state.contentData.searchResults);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    
    async function handleSubmit(e) {
        console.log(location)
        e.preventDefault();
        dispatch(setSearchResults(null));
        const userText = document.getElementById(`search-input-${location}`).value
        dispatch(setSearchText(userText));
        if (setShowSearchModal) {
            setShowSearchModal(true)
        }
        console.log('searching')
        const res = await UseCloudFunction('testImdbApi', {'searchText': userText})
        console.log(res.errorMessage);
        dispatch(setSearchResults(res.results));
    }
    
    return (
        <div className='search-box'>
            <form onSubmit={handleSubmit}>
                <input type="text" id={`search-input-${location}`} defaultValue={searchText ?? ''} className={`search-input ${isMobileDevice ? 'mobile': ''}`} placeholder="Search the streams..." />
            </form>
            <FontAwesomeIcon icon={faSearch} className={`search-icon ${isMobileDevice ? 'mobile': ''}`} onClick={handleSubmit} />
        </div>
    )
}

export default SearchInput