import React from 'react';
import './sidebar-container.styles.scss';
import SidebarLeft from './sidebar-left.component';

const SidebarLeftContainer = () => {
    
    return (
        <div className='sidebar-left-container'>
            <div className='g-card'>
                <SidebarLeft />
            </div>
        </div>
    )
}

export default SidebarLeftContainer