import { UseCloudFunction } from "firebase-utils/firebase.utils"

export const AddToWatchList = async (item, user, service) => {
    const obj = {
        id: item.id,
        desc: item.description,
        image: item.image,
        title: item.title,
        ts: new Date().getTime(),
        userId: user.appId,
        status: 'active',
        service: service
    }
    const res = await UseCloudFunction('addToWatchList', {'item': obj})
    console.log(res)
}