import React from 'react'
import './sidebar-right.styles.scss'
import WatchList from 'components/HOME/watchlist/watchlist.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const SidebarRight = () => {
    return (
        <div className='sidebar-right'>
            <div className='g-card top'>
                <div className='sidebar-title'>
                    <p>My Watch List</p>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
                <hr />
                <div className='scroll-box'>
                    <WatchList sidebar={true} />
                </div>
            </div>
            {/* <div className="g-space-20"></div> */}
            <div className='g-card bottom'>
                <div className='sidebar-title'>
                    <p>My Clans</p>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
                <hr />
            </div>
        </div>
    )
}

export default SidebarRight